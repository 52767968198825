<template>
  <eagle-form
    :form-key="formKey"
  ></eagle-form>
</template>
<script >
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './announceConfig'
export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'announce',
    meta: {},
  }),
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
    },
    async readApi() {
      const announces = await this.$api.collection.baseApi.getAnnounce();
      return {
        announce_provider:announces.find((e)=>e.type==="ADMIN_PROVIDER")?.content || '',
        announce_store:announces.find((e)=>e.type==="ADMIN_STORE")?.content || '',
      }
    },
    async updateApi(target, formData) {
      const formatData = {
        data: [
          {
            type: "ADMIN_PROVIDER",
            content: formData.announce_provider
          },
          {
            type: "ADMIN_STORE",
            content: formData.announce_store
          }
        ]
      }
      await this.$api.collection.baseApi.updateAnnounce(formatData);
      return await this.readApi();
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
