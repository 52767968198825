import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => this.vm?.$t('page.announce'),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      defaultData: this._defaultData,
      blocks: [
        {
          data: {
            announce_provider:{
              label: '供應商系統公告訊息',
              grid: { xl: 12, lg: 12, md: 12 },
              type: 'textarea',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            announce_store:{
              label: '商店系統公告訊息',
              grid: { xl: 12, lg: 12, md: 12 },
              type: 'textarea',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            
          },
        },
      ],
      dataAction: {
        delete: false,
      },
    }
  }
}

export default new formConfig()
